<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/pqrs'" :titleHeader="'Detalles del ususario'" />
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <section v-if="!isLoading" class="content-form">
                        <div class="content-inputs">
                            <label for="number_process">Nombres:</label>
                            <p class="input">{{ dataPqr.names }}</p>
                           
                        </div>
                        <div class="content-inputs" >
                            <label for="number_process">Apellidos:</label>
                            <p class="input">{{ dataPqr.surnames }}</p>
                           
                        </div>

                        <div class="content-inputs" >
                            <label for="number_process">Correo Electronico:</label>
                            <p class="input">{{ dataPqr.email }}</p>
                           
                        </div>
                        <div class="content-inputs" >
                            <label for="number_process">Telefono fijo:</label>
                            <p class="input">{{ dataPqr.landline }}</p>
                           
                        </div>
                        <div class="content-inputs" >
                            <label for="number_process">Telefono Celular:</label>
                            <p class="input">{{ dataPqr.cell_phone }}</p>
                           
                        </div>
                        <div class="content-inputs" >
                            <label for="number_process">Nombre PQRS:</label>
                            <p class="input">{{ dataPqr.select_option }}</p>
                           
                        </div>
                        <div class="content-inputs" >
                            <label for="number_process">Nombre Servicio:</label>
                            <p class="input">{{ dataPqr.select_service }}</p>
                           
                        </div>

                        <div class="content-inputs content-dcption" >
                            <label for="number_process">Descripcion:</label>
                            <p class="input ajust-text">{{ dataPqr.description }}</p>
                           
                        </div>

                        <!-- <div class="content-inputs" id="content-inputs">
                            <label for="type_process">Tipo de proceso *</label>
                            <input v-model="dataTender.type_proccess" class="input" name="type_process" type="text"
                                placeholder="Tipo de proceso" id="type_process" required>
                        </div> -->

                        <!-- <div class="content-inputs" id="content-inputs">
                            <label for="status">Estado *</label>
                            <input v-model="dataTender.status" class="input" name="status" type="text" placeholder="Estado"
                                id="status" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="entity">
                                Entidad *
                            </label>
                            <input v-model="dataTender.entity" class="input" name="entity" type="text" placeholder="Entidad"
                                id="entity" required>
                        </div> -->
                        <!-- <div class="content-inputs" id="content-inputs">
                            <label for="type_object">Tipo objeto *</label>
                            <input id="type_object" v-model="dataTender.typeObject" class="input" name="type_object"
                                type="text" placeholder="Tipo objeto" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="cuantia">Cuantia *</label>
                            <input id="cuantia" v-model="dataTender.cuantia" class="input" name="cuantia" type="text"
                                placeholder="Cuantia" required>
                        </div>

                        <div class="content-inputs" id="content-inputs">
                            <label for="date_licitacion">Fecha *</label>
                            <input id="date_licitacion" v-model="dataTender.date" class="input" name="date_licitacion"
                                type="date" placeholder="fecha" required>
                        </div> -->

                    </section>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            _idParam: '',
            isLoading: false,
            dataPqr: {},
        }
    },
    mounted() {
        this._idParam = "" + this.$route.params._id
        this.getInfoPqr(this._idParam)
    },
    methods: {
        async getInfoPqr(_id) {
            this.isLoading = true
            await db.collection('infoPqr')
                .doc(_id)
                .get().then((QuerySnapshot) => {
                    this.dataPqr = QuerySnapshot.data()
                    this.isLoading = false
                }).finally((error) => {
                    this.isLoading = false
                })
        }
    },
}
</script>
<style>
.content-dcption{
    max-height: 200px;
    overflow: auto;
}
.ajust-text{
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: 650px;
}

</style>