<template>
    <a-layout-header>
        <div class="container-header-module">
            <router-link :to="this.routeBack">
                <span> <i class="el-icon-back icon-route-back"></i>
                </span>
            </router-link>
            <h2 class="title-header-module">
                {{ this.titleHeader }}
            </h2>
        </div>
    </a-layout-header>
</template>

<script>
export default ({
    props: {
        routeBack: {
            type: String,
            default: () => "/dashboard",
        },
        titleHeader: {
            type: String,
            default: () => ""
        }
    },

    data() {
        return {
        }
    },
})

</script>

<style>
.container-header-module {
    display: flex;
    align-items: center;
}

.title-header-module {
    margin: 0px 0px 0px 20px;
}
</style>